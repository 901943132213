import React from "react";
import { Link } from "react-router-dom";
import { organizationDetails } from "../../data/constants";
import servicesTwoData from "./servicesTwoData";

const ServicesTwo = () => {
  return (
    <section className="our-service-wrapper bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title mt-5">
                <span>{organizationDetails.companyName}</span>
                <h2>TÜM HİZMETLER</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {servicesTwoData.map((data) => (
            <div className="col-xl-4 col-md-6 col-12" key={data.id}>
              <div className="single-service-card">
                <div
                  className="service-cover-img bg-cover"
                  style={{ backgroundImage: `url(${data.img})` }}
                ></div>
                <div className="content">
                  <div className="icon-title">
                    <div className="service-title">
                      <h4>
                        <p>{data.title}</p>
                      </h4>
                    </div>
                  </div>
                  <p>{data.desc}</p>
                  <Link to="/cilt-bakim" className="read-more">
                    DAHA FAZLA<i className="fal fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesTwo;
