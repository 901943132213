import React, { useState } from "react";
import HeaderTwoTop from "./HeaderTwoTop";
import Logo from "../../assets/images/logo.png";
import Myk2 from "../../assets/images/myk2.png";

import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const HeaderTwo = () => {
  const [search, setSearch] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <header className="header-wrap header-2">
      <HeaderTwoTop />
      <div className="main-header-wrapper">
        <div className="container-fluid align-items-center justify-content-center d-flex">
          <div className="logo me-5">
            <Link to="/">
              <img src={Logo} alt="logo" width="150" height="150" />
            </Link>
          </div>
          <div className="main-menu d-none d-lg-block">
            <ul>
              <li>
                <Link to="/">ANASAYFA</Link>
              </li>
              <li>
                <Link to="/hakkimizda">HAKKIMIZDA</Link>
                <ul>
                  <li>
                    <Link to="/yetki-belgesi">Yetki Belgesi</Link>
                  </li>
                  <li>
                    <Link to="/yasal-dayanak">Yasal Dayanağımız</Link>
                  </li>
                  <li>
                    <Link to="/yonetim">Yönetim Taahhüdümüz</Link>
                  </li>
                  <li>
                    <Link to="/kalite-politika">Kalite Politikamız</Link>
                  </li>
                  <li>
                    <Link to="/gizlilik">Gizlilik ve Güvenlik</Link>
                  </li>
                  <li>
                    <Link to="/kapsamlar">Kapsamlarımız</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/fiyat-listesi">FİYAT LİSTESİ </Link>
              </li>
              <li>
                <Link to="/myk-nedir">MYK NEDİR? (SSS)</Link>
              </li>
              <li>
                <Link to="/hizmetler">HİZMETLERİMİZ</Link>
              </li>
              <li>
                <Link to="/iletisim">İLETİŞİM</Link>
              </li>
              <li className="ms-5">
                <a
                  href="https://yenidunyamyk.voc-tester.com/basvuru"
                  target="_blank"
                >
                  Başvur
                </a>
              </li>
              <li>
                <a
                  href="https://portal.myk.gov.tr/index.php?option=com_sertifika_sorgula"
                  target="_blank"
                >
                  BELGE SORGULA
                </a>
              </li>
            </ul>
          </div>
          <div className="logo me-5">
            <a href="https://myk.gov.tr/" target="_blank">
              <img src={Myk2} alt="busico" width="100%" height="100" />
            </a>
          </div>
          <div className="d-inline-block d-lg-none">
            <div className="mobile-nav-wrap">
              <div id="hamburger" onClick={handleMobileMenu}>
                <i className="fal fa-bars"></i>
              </div>
              <MobileMenu
                mobileMenu={mobileMenu}
                handleMobileMenu={handleMobileMenu}
              />
            </div>
            <div className="overlay"></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
