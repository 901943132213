import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import Yonetim from "../Components/About/Yonetim";

const YonetimPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="YÖNETİM TAAHHÜDÜ"
        bannerBg={Epilasyon}
        currentPage="YÖNETİM TAAHHÜDÜ"
      />
      <Yonetim pt />
      <FooterTwo />
    </>
  );
};

export default YonetimPage;
