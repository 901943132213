import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();

  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>{organizationDetails.companyName}</span>
                      <h2> MİSYONUMUZ VE VİZYONUMUZ</h2>
                    </div>
                    <h6 className="mt-5">Misyonumuz</h6>
                    <p>
                      MYK tarafından, yeterlilikleri tanımlanmış standartlar ile
                      ulusal olarak tanınan kalite güvence sistemi çerçevesinde
                      mesleki anlamda yeterliliklerin sağlandığının tescil
                      edilmesidir. Kendini sürekli geliştiren bir anlayışla
                      konusuna hakim bağımsızlık, tarafsızlık ve gizlilik
                      ilkeleri doğrultusunda, en güvenilir yöntemler ve uzman
                      personel ile sınav ve belgelendirme hizmeti sunmaktır.
                    </p>
                    <h6 className="mt-5">Vizyonumuz</h6>
                    <p>
                      Mesleki yeterliliği güvence altına alarak nitelikli insan
                      kaynağının oluşmasını sağlamak, ulusal ve uluslararası
                      ölçekte tanınan, etkin ve saygın bir kurum olmak. Sektörde
                      sunduğumuz hizmet ile öncü, uluslararası platformda yer
                      edinmiş lider bir kuruluş olmaktır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center"> HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vizyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
