import React from "react";
import { Link } from "react-router-dom";
import FooterCta from "./FooterCta";
import FooterOneCopyrights from "./FooterOneCopyrights";
import { organizationDetails } from "../../data/constants";

const FooterTwo = () => {
  return (
    <footer className="footer-2 footer-wrap">
      <div className="footer-widgets-wrapper text-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-12">
              <div className="single-footer-wid site_info_widget">
                <div className="wid-title">
                  <h3>BİZE ULAŞIN</h3>
                </div>
                <div className="contact-us">
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-phone"></i>
                    </div>
                    <div className="contact-info">
                      <p>7 -24 Danışma Hattı </p>
                      <a href={`tel:${organizationDetails.phone}`}>
                        {organizationDetails.phone}
                      </a>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-envelope"></i>
                    </div>
                    <div className="contact-info">
                      <a href={`mailto:${organizationDetails.email}`}>
                        {organizationDetails.email}
                      </a>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-map-marker-alt me-4"></i>
                    </div>
                    <div className="contact-info ">
                      <address>{organizationDetails.address}</address>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 offset-xl-1 col-12">
              <div className="single-footer-wid">
                <div className="wid-title">
                  <h3>HAKKIMIZDA</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/hakkimizda">Hakkımızda</Link>
                  </li>
                  <li>
                    <Link to="/yetki-belgesi">Yetki Belgesi</Link>
                  </li>
                  <li>
                    <Link to="/yasal-dayanak">Yasal dayanağımız</Link>
                  </li>
                  <li>
                    <Link to="/yonetim">Yönetim Taahhüdümüz</Link>
                  </li>
                  <li>
                    <Link to="/misyon-vizyon">Misyon ve Vizyonumuz</Link>
                  </li>
                  <li>
                    <Link to="/kalite-politika">Kalite Politikamız</Link>
                  </li>
                  <li>
                    <Link to="/gizlilik">Gizlilik ve Güvenlik</Link>
                  </li>

                  <li>
                    <Link to="/kapsamlar">Kapsamlarımız</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-3 col-12">
              <div className="single-footer-wid newsletter_widget">
                <div className="wid-title">
                  <h3>SÖZLEŞMELER</h3>
                  <ul>
                    <li>
                      <a href="./itiraz-sikayet.docx">
                        İtiraz ve Şikayet Formu
                      </a>
                    </li>
                    <li>
                      <a href="./aday-sozlesmesi.docx">
                        Aday Sözleşmesi / Taahhütnamesi
                      </a>
                    </li>
                    <li>
                      <a href="./belge-kullanım-sozlesmesi.docx">
                        Belge Kullanım Sözleşmesi
                      </a>
                    </li>
                    <li>
                      <a href="./belgelendirme-prosoduru.doc">
                        Belgelendirme Prosedürü
                      </a>
                    </li>
                    <li>
                      <a href="#">Sınav Programı</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterCta />
      <FooterOneCopyrights />
    </footer>
  );
};

export default FooterTwo;
