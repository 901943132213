import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import AboutOne from "../Components/About/AboutOne";

const AboutPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="HAKKIMIZDA"
        bannerBg={Epilasyon}
        currentPage="HAKKIMIZDA"
      />
      <AboutOne pt />
      <FooterTwo />
    </>
  );
};

export default AboutPage;
