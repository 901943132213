import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Masaj from "../Components/Hizmetler/Masaj";

const HomeTwo = () => {
  return (
    <>
      <HeaderTwo />
      <Masaj />
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
