import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import { useNavigate, useParams, Link } from "react-router-dom";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();
  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12 mt-4"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <h2>Duyurular </h2>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
