import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Guzellik from "../Components/Hizmetler/Guzellik";

const HomeTwo = () => {
  return (
    <>
      <HeaderTwo />
      <Guzellik />
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
