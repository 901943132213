import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useNavigate, useParams, Link } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();

  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>{organizationDetails.companyName}</span>
                      <h2>YETKİ BELGESİ</h2>
                    </div>
                    <p>
                      “YENİ DÜNYA PERSONEL BELGELENDİRME MERKEZİ LTD.ŞTİ,
                      23.05.2023 tarihinde AB-0511-P akreditasyon numarası ile
                      Türk Akreditasyon Kurumu (TÜRKAK) tarafından akredite
                      edilmiş ve 04.08.2023 tarihinde YB-0358 kodu ile Mesleki
                      Yeterlilik Kurumu (MYK) tarafından yetkilendirilerek yetki
                      kapsamındaki ulusal yeterliliklerde sınav ve belgelendirme
                      faaliyetlerine resmen başlamıştır.
                    </p>
                    <p>
                      <a href="https://portal.myk.gov.tr/index.php?option=com_kurulus_ara&view=kurulus_ara&layout=kurulus&kurId=21046185">
                        YETKİ BELGEMİZ İÇİN TIKLAYINIZ.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center">HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vizyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
