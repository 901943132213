import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();

  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12">
                  <div className="img-block-pop-video"></div>
                </div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>{organizationDetails.companyName}</span>
                      <h2> Gizlilik ve Güvenlik</h2>
                    </div>
                    <p>
                      Kişisel verileriniz, sizin onayınızı almadığımız ya da
                      kanunen yapmamız gerekmediği sürece, bizim tarafımızdan
                      hiçbir biçimde üçüncü şahıslarca kullanım için
                      aktarılmayacaktır. Kişisel bilgileriniz hiçbir şekilde
                      sizi rahatsız edecek şekilde kullanılmaz. Eski veya yanlış
                      olduğunu düşündüğünüz takdirde, sistemimizde saklanan
                      kişisel verileri gözden geçirme ve değiştirme hakkına
                      sahipsiniz. Çocukların mahremiyetini korumanın önemi
                      ışığında, yasal temsilcisinin doğrulanabilir ön onayı
                      olmaksızın 18 yaşından küçük olduğunu bildiğimiz bir
                      bireyle ilgili bilgileri toplamıyor, işlemiyor internet
                      sitemizde kullanmıyoruz. Söz konusu yasal temsilci, talep
                      üzerine, çocuk tarafından sunulan bilgileri gözden geçirme
                      ve/veya silinmesini isteme hakkını sahiptir. Gizlilik
                      beyanımızdaki herhangi bir değişiklik bu gizlilik
                      ifadesinde, ana sayfamızda ve uygun gördüğümüz diğer
                      yerlerde ilan edilecektir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center"> HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vizyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
