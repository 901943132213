import React from "react";
import Logoo from "../../assets/images/logo.svg";

const BestFeatures = () => {
  return (
    <section className="best-featured-wrapper pb-5 mb-5">
      <div className="container">
        <div className="row mtm-40 align-items-center">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-flow-chart" />
              </div>
            </div>
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-writing" />
              </div>
            </div>
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-interior-design" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-md-0 mt-5 mb-5 mt-md-0 mb-md-0 col-md-6 col-12">
            <div className="center-img text-center mbm-50">
              <img src={Logoo} alt="beko" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-secure-shield" />
              </div>
            </div>
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-team" />
              </div>
            </div>
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-profits" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestFeatures;
