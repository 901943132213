import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();

  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12">
                  <div className="img-block-pop-video"></div>
                </div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>{organizationDetails.companyName}</span>
                      <h2> KALİTE POLİTİKALARIMIZ</h2>
                    </div>
                    <p>
                      <b>{organizationDetails.companyName}</b> olarak evrensel
                      ve kurumsal etik değerler çerçevesinde tarafsız, şeffaf,
                      güvenilirlik ilkelerini benimseyen, kendi iç dinamiklerini
                      sürekli geliştiren, yenilikçi anlayışla çalışan, ihtiyaç
                      ve beklentileri duyarlı bir şekilde karşılayan ve kurumsal
                      standartlarından ödün vermeyen .MYK ve{" "}
                      <b>TS EN ISO/IEC 17024 Standardı</b>'na ve{" "}
                      <b>TÜRKAK Rehber Dokümanlar</b>'a uygun olarak personel
                      belgelendirme faaliyetlerini gerçekleştirmektedir.
                      Sektöründe saygın, nitelikli, yeterli ve uzman personel
                      ile çalışan, sorumlu bir kuruluş olmaktır, Gizlilik,
                      dürüstlük, bağımsızlık, güvenilirlik, objektiflik ve
                      tarafsız muamele hususlarını etkileyebilecek ticari türden
                      ya da başka türden hiçbir duruma izin vermemektedir. Tüm
                      adaylar ve başvuru sahiplerine adil, tarafsız hakkaniyet
                      ölçülerinde herkese eşit davranarak, aynı belgelendirme
                      kurallarını uygulayarak mesleki becerilerini sınavlarla
                      ortaya koymaktadır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center"> HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vizyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
