import { Row, Container, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();
  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className=" col-xs-12 col-md-12 col-12">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>YENİ DÜNYA BELGELENDİRME</span>
                      <h2>FİYAT LİSTESİ </h2>
                    </div>
                    <p>
                      <Table>
                        <thead>
                          <tr>
                            <th>SEVİYE</th>
                            <th></th>
                            <th>TOPLUMSAL HİZMETLER</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>SINAV ÜCRETİ</th>
                            <th>BELGE ÜCRETİ</th>
                            <th>TOPLAM</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>SEVİYE-4</td>
                            <td></td>
                            <td>EPİLASYON UZMANI</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺7,250.00</td>
                            <td>₺750.00</td>
                            <td>₺8,000.00</td>
                          </tr>
                          <tr>
                            <td>SEVİYE-3</td>
                            <td></td>
                            <td>DÖVME UYGULAYICISI</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺4,250.00</td>
                            <td>₺750.00</td>
                            <td>₺5,000.00</td>
                          </tr>
                          <tr>
                            <td>SEVİYE-4</td>
                            <td></td>
                            <td>GÜZELLİK UZMANI</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺7,750.00</td>
                            <td>₺750.00</td>
                            <td>₺8,500.00</td>
                          </tr>
                          <tr>
                            <td>SEVİYE-3</td>
                            <td></td>
                            <td>MASAJ UYGULAYICISI</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺5,150.00</td>
                            <td>₺750.00</td>
                            <td>₺5,900.00</td>
                          </tr>
                          <tr>
                            <td>SEVİYE-3</td>
                            <td></td>
                            <td>MAKYAJ UYGULAYICISI |TÜM BİRİMLER</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺7,400.00</td>
                            <td>₺750.00</td>
                            <td>₺8,150.00</td>
                          </tr>
                          <tr>
                            <td>SEVİYE-3</td>
                            <td></td>
                            <td>
                              MAKYAJ UYGULAYICISI | B1 MAKYAJ UYGULAMALARI
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺3,450.00</td>
                            <td>₺750.00</td>
                            <td>₺4,200.00</td>
                          </tr>
                          <tr>
                            <td>SEVİYE-3</td>
                            <td></td>
                            <td>MAKYAJ UYGULAYICISI | B2 KALICI MAKYAJ</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺4,450.00</td>
                            <td>₺750.00</td>
                            <td>₺5,200.00</td>
                          </tr>
                          <tr>
                            <td>SEVİYE-3</td>
                            <td></td>
                            <td>CİLT BAKIMI UYGULAYICISI</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₺3,200.00</td>
                            <td>₺750.00</td>
                            <td>₺3,950.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </p>
                  </div>
                </div>
                <div>
                  <p>
                    Detaylı fiyat listesini indirmek için
                    <a href="./myk-fiyat-detaylı.xlsx" target="_blank">
                      &nbsp; tıklayınız.
                    </a>
                  </p>
                </div>
                <br /> <br />
                <div className=" col-xs-12 col-12">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      textAlign: "center",
                    }}
                  >
                    MYK SINAV ÜCRETLERİNİN YATIRILMASI GEREKEN RESMİ HESAP
                    NUMARAMIZ
                  </p>
                </div>
                <br />
                <br />
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #ced4da" }}
                >
                  <div className="col-xl-2 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      BANKA:
                    </p>
                  </div>
                  <div className="col-xl-10 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      FİNANS BANKASI
                    </p>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #ced4da" }}
                >
                  <div className="col-xl-2 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      HESAP İSMİ:
                    </p>
                  </div>
                  <div className="col-xl-10 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      YENİ DÜNYA PERSONEL BELGELENDİRME HİZMETLERİ
                    </p>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #ced4da" }}
                >
                  <div className="col-xl-2 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      IBAN:
                    </p>
                  </div>
                  <div className="col-xl-10 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      TR26 0011 1000 0000 0113 9448 73
                    </p>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #ced4da" }}
                >
                  <div className="col-xl-2 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      AÇIKLAMA:
                    </p>
                  </div>
                  <div className="col-xl-10 col-xs-12 col-12">
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Açıklama kısmına gireceğiniz sınav bilgisi ve aday
                      adı-soyadını yazınız.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
