import Epilasyon from "../../assets/images/epilasyon.jpg";
import "react-modal-video/scss/modal-video.scss";
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";

const AboutMyk = ({ pt }) => {
  return (
    <Container>
      <section
        className={
          pt
            ? "about-section section-padding"
            : "about-section section-padding pt-0"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="img-block-pop-video">
                <img src={Epilasyon} alt="busico" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 col-12">
              <div className="block-contents">
                <div className="section-title">
                  <span>YENİ DÜNYA BELGELENDİRME</span>
                  <h2>EPİLASYON UZMANI SEVİYE 4 (REV 0)</h2>
                </div>
                <p>
                  Epilasyon uzmanı olarak çalışabilmek için Mesleki Yeterlilik
                  Kurumu'nun 18UY0344-4 yeterlilik kodlu Epilasyon uzmanı Ulusal
                  Yeterlilik sertifikasına sahip olmalıdır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="">
        <h2 className="text-center">Belgelendirme Şartları</h2>
      </div>

      <Accordion className="mb-5">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            BİRİMLERİN GRUPLANDIRILMA ALTERNATİFLERİ VE İLAVE ÖĞRENME ÇIKTILARI
          </Accordion.Header>
          <Accordion.Body>
            Ulusal Mesleki Yeterlilik Sisteminin kurulmasındaki temel amaç
            eğitim ile iş yaşamının nitelik talepleri arasında işlevsel bir bağ
            kurmaktır. Bu sistem Türkiye'nin küresel ekonomide rekabet
            edebilmesi için ihtiyaç duyduğu nitelikli işgücünün yetiştirilmesini
            sağlayacaktır. İş dünyasının işgücüne yönelik mevcut ihtiyaçları ve
            geleceğe dönük eğilimleri, hazırlanmasına katkı sağlayacağı ulusal
            meslek standartları yoluyla eğitim sistemine yansıyacaktır. Yine
            eğitim dünyası da, amaçlarından birisi olan nitekli iş gücünün
            yetiştirilmesini bu sistem sayesinde etkin ve esnek bir şekilde
            gerçekleştirecektir. Bu sistemin en güçlü yönlerinden birisi, hayat
            boyu öğrenme anlayışını kabul etmesidir. Bu kapsamda herhangi bir
            eğitim almadan çalışarak beceri edinen bireyleri sahip oldukları
            becerileri belgelendirmeleri, bireylere farklı meslekler veya
            sektörler arasında yatay ve dikey geçişleri sağlaması, bulunduğu
            sektörde ve icra ettiği meslekte ortaya çıkan yeni gelişmeleri esnek
            bir şekilde mesleğine yansıtabilmesi ve bunu belgelendirebilmesi
            gibi imkanlar bu sistem sayesinde mümkün olacaktır.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            MESLEKİ YETERLİLİK KURUMU’NUN SİSTEMDEKİ ROLÜ NEDİR?
          </Accordion.Header>
          <Accordion.Body>
            Bu sistemde koordinasyon MYK tarafından sağlanmakta, MYK sistemde
            izleme, değerlendirme, görevlendirme ve yetkilendirme görevlerini
            üstlenmektedir. Kurumun faaliyet alanı eğitim ve istihdam kesimini
            birlikte ilgilendirdiğinden bütün karar süreçlerinde de ilgili
            sosyal tarafların katkılarının esas alındığı bir yaklaşım
            benimsenmiştir. Ulusal Mesleki Yeterlilik Sistemi’nin bileşenleri
            olan ulusal meslek standartları ve ulusal yeterlilikler; sektörlere
            ve mesleklere ilişkin yetkinliği ve temsil gücü olan ve MYK
            tarafından yetkilendirilmiş kamu kurum/kuruluşları, işçi, işveren,
            meslek örgütleri ve sivil toplum örgütleri tarafından iş dünyasının
            ihtiyaçları ve geleceğe dönük eğilimleri ile eğitim dünyası ve diğer
            sosyal tarafların katkılarının esas alınması suretiyle
            hazırlanmaktadır. Ulusal meslek standartlarının hazırlanması, sınav
            ve belgelendirme sürecinde ölçme ve değerlendirmeyi sağlayacak
            ulusal yeterliliklerin hazırlanmasında temel girdi oluşturmaktadır.
            Sınav ve belgelendirme süreci de MYK’nın gözetimi ve denetimi
            altında gerçekleştirilmektedir. Belgelendirilmek istenilen
            yeterliliklerde TÜRKAK ya da Avrupa Akreditasyon Birliği ile çok
            taraflı tanıma anlaşması imzalamış akreditasyon kurumlarından TS EN
            ISO/IEC 17024 (Uygunluk Değerlendirmesi - Personel Belgelendirmesi
            Yapan Kuruluşlar İçin Genel Şartlar) standardına uygun oluşturulmuş
            sistem dâhilinde akredite edilmiş belgelendirme kuruluşları MYK
            tarafından yetkilendirilerek bu kuruluşların yaptığı sınavlarda
            başarılı olanlara MYK belgelerinin verilmesi sağlanmaktadır.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>ULUSAL MESLEK STANDARDI NEDİR?</Accordion.Header>
          <Accordion.Body>
            (UMS) bir mesleğin başarı ile icra edilebilmesi için Mesleki
            Yeterlilik Kurumu tarafından kabul edilen gerekli bilgi, beceri,
            tavır ve tutumların neler olduğunu gösteren asgari normdur.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            ULUSAL MESLEK STANDARTLARI HANGİ SIKLIKTA GÜNCELLENMEKTEDİR?
          </Accordion.Header>
          <Accordion.Body>
            Ulusal meslek standartları en geç beş yılda bir yeniden
            değerlendirilerek güncellenmektedir. Beş yıllık süre dolmadan gelen
            güncelleme talepleri de MYK tarafından belirlenen usul ve esaslar
            çerçevesinde değerlendirilmekte ve taleplerin değerlendirilmesi
            sonucunda güncelleme yapılmasına karar verilen ulusal meslek
            standartları ile ilgili güncelleme çalışmaları yürütülmektedir.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            ULUSAL MESLEK STANDARTLARININ İŞ PİYASASINA VE EĞİTİM DÜNYASINA
            FAYDASI NEDİR?
          </Accordion.Header>
          <Accordion.Body>
            Ulusal meslek standartları, bireylerin mesleğini icra edebilmesi
            için gerekli bilgi, beceri, tavır ve tutumların neler olduğunu
            tanımlamakta bir diğer ifade ile mesleklerin profilini ortaya
            koymaktadır. Söz konusu standartlar, sektörün ihtiyaçları
            doğrultusunda, MYK tarafından görevlendirilen sektörünü temsil
            kabiliyetine sahip kurum/kuruluşlarca ya da MYK tarafından
            oluşturulan meslek ve sektörle ilgili tarafların temsil edildiği
            (kamu kesimi ve sosyal taraf temsilcilerinin yer aldığı) çalışma
            gruplarınca hazırlanmaktadır. Bu sayede meslek standartları, ilgili
            meslekte istihdam edilen bireylerden iş piyasasının talep ettiği
            nitelikleri tanımlamaya imkân sağlamaktadır. Ulusal meslek
            standartları temel alınarak hazırlanan ulusal yeterliliklerde
            yürütülen ölçme, değerlendirme ve belgelendirme faaliyetleri iş
            piyasasının talep ettiği niteliklere sahip bireylerin
            belirlenmesinde önemli bir rol üstlenmektedir. Bu bağlamda ulusal
            meslek standartları ölçme, değerlendirme ve belgelendirme
            şartlarının tanımlandığı ulusal yeterliliklere girdi teşkil
            etmektedir. Ulusal meslek standartları, orta öğretim düzeyinde Milli
            Eğitim Bakanlığı ve yükseköğretim düzeyinde üniversiteler tarafından
            meslekî ve teknik eğitim ve öğretim programları ile de uyumlu hale
            getirilmekte ve bu sayede iş piyasasının talep ettiği meslek
            profiline uygun bireylerin yetişmesine katkı sağlamaktadır. İş
            analizine dayalı ve iş piyasasının ihtiyaçları doğrultusunda
            hazırlanan ulusal meslek standartları; iş piyasasında faaliyet
            gösteren kuruluşlara insan kaynakları yönetim süreçlerinde (görev
            tanımlarının oluşturulması, personel seçiminin yapılması, eğitim,
            performans değerleme, iş değerleme vb.) kullanabilmeleri açısından
            önemli girdi sağlamaktadır.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>ULUSAL YETERLİLİK NEDİR? </Accordion.Header>
          <Accordion.Body>
            Ulusal yeterlilikler; - Ulusal ya da uluslararası meslek
            standartları temel alınarak hazırlanan, - Öğrenme,
            ölçme-değerlendirme amacıyla kullanılan, - Bireylerin mesleğini
            başarı ile icra etmesi için sahip olmaları gereken bilgi, beceri ve
            yetkinlikler ile bu bilgi, beceri ve yetkinlikleri kanıtlamaları
            için nasıl bir ölçme ve değerlendirme sürecinden geçmeleri
            gerektiğini açıklayan, - MYK tarafından onaylanarak yürürlüğe giren
            teknik dokümanlardır.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            ULUSAL MESLEK STANDARTLARI VE ULUSAL YETERLİLİKLER ARASINDAKİ TEMEL
            FARK NEDİR?
          </Accordion.Header>
          <Accordion.Body>
            Ulusal meslek standartları (UMS); bir mesleğin başarı ile icra
            edilebilmesi için gerekli bilgi, beceri, tavır ve tutumların neler
            olduğunu tanımlamakta bir diğer ifade ile mesleklerin profilini
            ortaya koymaktadır. Ulusal yeterlilikler ise, meslek standartlarını
            temel alarak geliştirilmekte ve ilgili ulusal yeterlilikte MYK
            Mesleki Yeterlilik Belgesi sahibi olmak isteyen bireylerin nasıl bir
            ölçme ve değerlendirme sürecine tabi tutulacağına, başarı ve
            belgelendirme şartlarına ilişkin unsurları içermektedir. Ulusal
            yeterlilikler ile uyumlu ölçme, değerlendirme ve belgelendirme
            faaliyetleri MYK tarafından yetkilendirilmiş belgelendirme
            kuruluşlarınca gerçekleştirilmektedir. Yapılan sınavlar sonucu
            başarılı olan bireylere MYK Mesleki Yeterlilik Belgesi
            verilmektedir.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            BİR KİŞİ BİRDEN FAZLA ALAN İÇİN YETERLİLİK BELGESİNE SAHİP OLABİLİR
            Mİ?{" "}
          </Accordion.Header>
          <Accordion.Body>
            Hayat boyu öğrenmenin ve sürekli eğitimin desteklendiği ülkeler
            arasında hareketliliğin amaçlandığı Ulusal Mesleki Yeterlilik
            Sisteminde, kişilerin birden fazla alanda yeterlilik belgesine sahip
            olabilmeleri mümkündür. Kişi mevcut belgesiyle çalıştığı işinden
            memnun olmadığında yeni yeterliliklere göre eğitim alarak ve
            kendisini geliştirerek yatay ve dikey geçişlerle farklı
            yeterliliklerde ve seviyelerde belge alma imkânına sahiptir.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            SINAV VE BELGELENDİRME SÜRECİ NASIL İŞLEMEKTEDİR?
          </Accordion.Header>
          <Accordion.Body>
            Sınav ve belgelendirme süreci, belirlenen ulusal yeterliliklerde
            akredite olmuş ve MYK tarafından yetkilendirilmiş kurum/kuruluşlarca
            yürütülmektedir. Belgelendirme için gerekli tüm işlemler MYK’nın
            gözetim ve denetimi altında, ilgili yeterlilikte yetkilendirilmiş
            belgelendirme kuruluşu tarafından yürütülmektedir. Yetkilendirilmiş
            kuruluşun yapacağı ölçme ve değerlendirme sonucunda
            belgelendirilmesi uygun olan kişilere MYK Yönetim Kurulu’nun onayı
            ile MYK Mesleki Yeterlilik Belgeleri verilmektedir. Mesleki
            Yeterlilik Belgesi almak için gereken şartlar ise her meslek için
            geliştirilen Ulusal Yeterliliklerle belirlenmektedir. Buna göre MYK
            Mesleki Yeterlilik Belgesi almak isteyen adaylar ilgili yeterlilikte
            yetkilendirilmiş personel belgelendirme kuruluşlarından birine
            başvururlar. Bu konuda yürütülen işlemlerin detayları MYK tarafından
            çıkarılan Mesleki Yeterlilik, Sınav ve Belgelendirme Yönetmeliği ile
            belirlenmiştir.
          </Accordion.Body>
        </Accordion.Item>
        <div className="align-item-center">
          <h2 className="text-center">SIKÇA SORULAN SORULAR</h2>
        </div>
        <Accordion.Item eventKey="9">
          <Accordion.Header>
            SINAV SONUÇLARIMI VE BELGE HAK EDİP ETMEDİĞİMİ NEREDEN ÖĞRENEBİLİRİM
            ?{" "}
          </Accordion.Header>
          <Accordion.Body>
            MYK Web Portalındaki “Sorgulama” başlığı altındaki Aday Sorgulama
            bölümünden veya e-Devlet Kapısı üzerinden sınav sonuçlarınızı, belge
            bilgilerinizi ve teşvik işlemlerinizi görebilirsiniz. Söz konusu
            bilgiler yetkilendirilmiş belgelendirme kuruluşları tarafından
            Portala işlenmektedir, bilgilerde eksiklik veya hata görürseniz
            sınavlarına girmiş olduğunuz yetkilendirilmiş belgelendirme kuruluşu
            ile görüşüp bilgilerin düzeltilmesini talep edebilirsiniz.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>
            SINAVLARIN BİR KISMINDAN BAŞARISIZ OLMUŞUM, BAŞARISIZ OLDUĞUM
            SINAVLARA BAŞKA BİR KURULUŞTA GİREBİLİR MİYİM?
          </Accordion.Header>
          <Accordion.Body>
            Farklı kuruluşlarda birim birleştirme yapılabilir. Örneğin; İnşaat
            Boyacısı ulusal yeterliliğinin A1 birimindeki sınavlara X
            yetkilendirilmiş belgelendirme kuruluşunda, A2 birimindeki sınavlara
            Y yetkilendirilmiş belgelendirme kuruluşunda girilebilir. Tüm
            yeterlilik birimlerinden başarılı olunması halinde en son
            sınavlarına girilen kuruluş belge talebi oluşturabilir. - Farklı
            kuruluşlarda aynı yeterlilik birimi için sınav birleştirme
            yapılamaz. Örneğin; İnşaat Boyacısı ulusal yeterliliğinin A1
            biriminin T1 sınavına X kuruluşunda, P1 sınavına Y kuruluşunda
            girilemez. Aynı kuruluşta bir birimin içerdiği tüm sınavlara
            girilmesi ve başarılı olunması halinde birim başarısı elde
            edilebilir.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11">
          <Accordion.Header>
            GÖZETİM NEDİR? SINAVLARINA GİRMİŞ OLDUĞUM KURULUŞ BENDEN ÇEŞİTLİ
            BELGELER İSTİYOR, BU BELGELERİ VERMEK ZORUNDA MIYIM?{" "}
          </Accordion.Header>
          <Accordion.Body>
            Gözetim, MYK Mesleki Yeterlilik Belgesi sahibinin belgesinin
            geçerlilik süresi içerisinde ilgili mesleki yeterliliği
            sürdürdüğünün tespit edilmesi amacıyla yapılan izleme ve
            değerlendirme faaliyetleridir. Yetkilendirilmiş belgelendirme
            kuruluşları ulusal yeterliliklerde belirtilen gözetim şartlarına
            uygun olarak gözetim zamanı geldiğinde belge sahiplerinden o
            meslekte aktif olarak çalışmayı sürdürdüğünü gösterir kanıt ve
            kayıtları iletmelerini talep ederler. - Belge sahibi istenen kanıt
            ve kayıtları ilgili yetkilendirilmiş belgelendirme kuruluşuna
            iletmelidir aksi takdirde kuruluş ilgili belgeyi askıya alma ve
            iptal etme hakkına sahiptir.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="12">
          <Accordion.Header>
            BELGEMİN GEÇERLİLİK SÜRESİ BİTİNCE NE OLUYOR? BELGE YENİLEME NE
            ANLAMA GELMEKTEDİR?
          </Accordion.Header>
          <Accordion.Body>
            Belge yenileme, MYK Mesleki Yeterlilik Belgesinin geçerlilik süresi
            sonunda belge sahibinin mesleki yeterliliğini sürdürdüğünün tespit
            edilmesi ve mesleki yeterliliğini devam ettiren kişinin yeniden
            belgelendirilmesi faaliyetleridir. MYK Mesleki Yeterlilik Belgeli
            kişi geçerlilik süresi bitmeden önce belgesini yenileyebilir, eğer
            yenileme işlemlerini yapmazsa geçerlilik süresi tamamlandığında
            belgesinin geçerliliği sona erer. Yenileme işlemlerinin belgesi
            alınmış aynı Kuruluşta yapılması zorunlu değildir, ilgili
            yeterlilikte yetkilendirilmiş belgelendirme kuruluşlarından tercih
            edilen herhangi birinde belge yenileme yapılabilir.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="13">
          <Accordion.Header>
            SINAV SONUÇLARIMDA BAŞARILI OLDUĞUMU GÖRDÜM, BELGEMİ NE ZAMAN VE
            NERDEN ALABİLİRİM?
          </Accordion.Header>
          <Accordion.Body>
            Yetkilendirilmiş belgelendirme kuruluşları sınavlar sonucunda
            prosedürlerinde belirtilen süre zarfında belge taleplerini
            Kurumumuza bildirmektedir. Belge talebi Kurumumuza iletildikten
            sonra en geç 5 iş günü içerisinde belge ve kimlik kartları
            yetkilendirilmiş belgelendirme kuruluşlarına iletilmektedir.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="14">
          <Accordion.Header>
            SINAV SONUCUNDA 90 PUAN ALDIĞIM AMA BAŞARISIZ OLDUĞUM YAZIYOR, BU
            NASIL MÜMKÜN OLUYOR?
          </Accordion.Header>
          <Accordion.Body>
            Ulusal yeterliliklerin büyük bir çoğunluğunda kritik adımlar yer
            almaktadır. Kritik adım, sınavın başarıyla tamamlanması için adayın
            mutlaka eksiksiz yerine getirmesi gereken iş ve işlemleri ifade
            eder. Kritik adımlarda başarılı olamamış adaylar sınav sonucunda
            hangi notu alırsa alsın o sınavdan başarısız sayılmaktadırlar.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="15">
          <Accordion.Header>
            BELGEMİ KAYBETTİM, YENİDEN BASILMASINI VE BANA GÖNDERİLMESİNİ
            SAĞLAYABİLİR MİSİNİZ?
          </Accordion.Header>
          <Accordion.Body>
            Belgeyi aldığınız yetkilendirilmiş belgelendirme kuruluşu ile
            görüşmeniz ve yeniden basım talebinizi Kuruluşa iletmeniz hâlinde
            ilgili Kuruluş belge ve kimlik kartı basım taleplerinizi Kurumumuza
            iletebilir. - Yetkilendirilmiş belgelendirme kuruluşları, belgesinin
            ve/veya kimlik kartının yeniden basılmasını talep eden adaydan belge
            masraf karşılığı ile gerekli diğer bilgi ve belgeleri talep eder.
            Yeniden basım talepleri Kurumumuza iletildikten sonra en geç 5 iş
            günü içerisinde belge ve kimlik kartları yetkilendirilmiş
            belgelendirme kuruluşlarına iletilmektedir.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default AboutMyk;
