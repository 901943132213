import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { organizationDetails } from "../../data/constants";

const AboutTwo = () => {
  return (
    <section className="about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-12 pe-xl-0">
            <div
              className="about-cover-bg bg-cover me-xl-5"
              style={{ backgroundImage: `url(${Logo})` }}
            ></div>
          </div>
          <div className="col-xl-6 mt-5 mt-lg-0 col-12">
            <div className="block-contents">
              <div className="section-title">
                <span>{organizationDetails.companyName}</span>
                <h2>Şirket Profili</h2>
              </div>
              <blockquote>
                "Bilginin sermaye olarak kabul edildiği günümüz dünyasında,
                sosyal, kültürel ve ekonomik anlamda geleceğe yatırım yapmak
                isteyen toplumlar öncelikle insan kaynağına yatırım
                yapmalıdırlar. Bilgiyi elde etmenin yanı sıra bu bilgiyi etkin
                bir şekilde kullanmak, geliştirmek ve yenilemek de önemlidir."
              </blockquote>
            </div>

            <p>
              {organizationDetails.companyName} bu kavramın gerçekliğe dökülmesi
              kapsamında 2022 yılında faaliyetlerine başlamıştır. İnsan
              kaynağının niteliklerine göre vasıflandırılması ve bu
              vasıflandırmaya uygun belgelendirme yapılması günümüz bilgi
              toplumlarında bir gereklilik haline gelmiştir.Bu gereklilikten
              hareketle, "Personel Belgelendirmesi" ile alakalı çalışmaların
              yakın takipçisi olmuş ve bu yapıya uyum sağlayan akredite bir
              "Belgelendirme Kuruluşu" olarak hizmet sunmayı amaç edinmiştir.
              Akreditasyon yetkilendirilmiş bir kuruluşun, belli işleri yapan
              bir kuruluş ya da kişinin yeterliliğine resmi tanınırlık verilmesi
              işlemidir. Akredite edilmiş bir hizmet ise, o hizmetin sunumunun
              uluslararası kabul görmüş kalite yeterlilik kriterlerine uygun
              olduğunun akreditasyon hizmeti verme konusunda yetkilendirilmiş,
              bağımsız ve tarafsız bir akreditasyon kuruluşu tarafından tescil
              edildiğini ifade eder. Maddi kaygıdan uzak hedefleri olan{" "}
              {organizationDetails.companyName}, sektörün en tecrübeli
              profesyonelleri ile herkes için tarafsız ve adil belgelendirme
              kurallarını uygulamaktadır. Bağımsızlık ve tarafsızlık
              ilkelerinden asla taviz vermeyen {organizationDetails.companyName}{" "}
              yasal mevzuat ve yükümlülüklerini koşullar ne olursa olsun yerine
              getirmeyi taahhüt ve beyan eder.
            </p>

            <Link to="/hakkimizda" className="theme-btn mt-35">
              daha fazlası
            </Link>
          </div>
        </div>
      </div>
      <h2 className="section-rotate-title d-none d-xxl-block">ŞİRKET</h2>
    </section>
  );
};

export default AboutTwo;
