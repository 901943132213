import { v4 as uuidv4 } from "uuid";
import img1 from "../../assets/images/cilt-bakimi.jpg";
import img2 from "../../assets/images/tatto.jpg";
import img3 from "../../assets/images/epilasyon.jpg";
import img4 from "../../assets/images/guzellik.jpg";
import img6 from "../../assets/images/makyaj.jpg";
import img7 from "../../assets/images/masaj.jpg";

const servicesOneData = [
  {
    id: uuidv4(),
    bg: img1,
    title: "1	CİLT BAKIM UYGULAYICISI SEVİYE 3 (Rev 1)	",
    desc: "Cilt bakım uygulayıcısı olarak çalışabilmek için Mesleki Yeterlilik Kurumu'nun 17UY0280-3 yeterlilik kodlu Makyaj Uygulayıcısı Ulusal Yeterlilik sertifikasına sahip olmalıdır.",
  },
  {
    id: uuidv4(),
    bg: img2,
    title: "DÖVME UYGULAYICISI SEVİYE 3 (Rev 2)",
    desc: "Dövme Uygulayıcısı olarak çalışabilmek için Mesleki Yeterlilik Kurumu'nun 16UY0248-3 yeterlilik kodlu Dövme Uygulayıcısı Ulusal Yeterlilik sertifikasına sahip olmalıdır.",
  },
  {
    id: uuidv4(),
    bg: img3,
    title: "EPİLASYON UZMANI SEVİYE 4 (Rev 0)",
    desc: "Epilasyon uzmanı olarak çalışabilmek için Mesleki Yeterlilik Kurumu'nun 18UY0344-4 yeterlilik kodlu Epilasyon uzmanı Ulusal Yeterlilik sertifikasına sahip olmalıdır.",
  },
  {
    id: uuidv4(),
    bg: img4,
    title: "GÜZELLİK UZMANI SEVİYE 4 (Rev 2)",
    desc: "Güzellik uzmanı olarak çalışabilmek için Mesleki Yeterlilik Kurumu'nun 16UY0244-4 yeterlilik kodlu Güzellik Uzmanı Ulusal Yeterlilik sertifikasına sahip olmalıdır.",
  },
  {
    id: uuidv4(),
    bg: img6,
    title: "6	 MAKYAJ UYGULAYICISI SEVİYE 3 (Rev 2)",
    desc: "Makyaj Uygulayıcısı olarak çalışabilmek için Mesleki Yeterlilik Kurumu'nun 16UY0242-3 yeterlilik kodlu Makyaj Uygulayıcısı Ulusal Yeterlilik sertifikasına sahip olmalıdır.",
  },
  {
    id: uuidv4(),
    bg: img7,
    title: "MASAJ UYGULAYICISI SEVİYE 3 (Rev 1)",
    desc: "Masaj Uygulayıcısı olarak çalışabilmek için Mesleki Yeterlilik Kurumu'nun 14UY0197-3 yeterlilik kodlu Masaj Uygulayıcısı Ulusal Yeterlilik sertifikasına sahip olmalıdır.",
  },
];

export default servicesOneData;
