import React from "react";
import Logo from "../../assets/images/logo.svg";

const WhyUs = () => {
  return (
    <section className="why-choice-us section-padding m-5">
      <div
        className="why-choice-bg bg-cover d-none d-xl-block"
        style={{ backgroundImage: `url(${Logo})` }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="offset-xl-6 offset-lg-4 col-lg-8 col-xl-6 col-12">
            <div className="block-contents">
              <div className="section-title">
                <span>NEDEN BİZİ SEÇMELİSİNİZ</span>
                <h2>İNSANLAR GÜVEN İÇİN BİZİ SEÇİYOR</h2>
              </div>
            </div>
            <div className="single-icon-circle-item">
              <div className="icon">
                <i className="flaticon-team" />
              </div>
              <div className="contents">
                <h3>KALİTE</h3>
                <p>
                  Gizlilik, dürüstlük, bağımsızlık, güvenilirlik, objektiflik ve
                  tarafsız muamele hususlarını etkileyebilecek ticari türden ya
                  da başka türden hiçbir duruma izin vermemektedir.
                </p>
              </div>
            </div>
            <div className="single-icon-circle-item">
              <div className="icon">
                <i className="flaticon-secure-shield"></i>
              </div>
              <div className="contents">
                <h3>GÜVENİLİR</h3>
                <p>
                  Gizlilik ve Güvenlik Kişisel verileriniz, sizin onayınızı
                  almadığımız ya da kanunen yapmamız gerekmediği sürece, bizim
                  tarafımızdan hiçbir biçimde üçüncü şahıslarca kullanım için
                  aktarılmayacaktır.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
