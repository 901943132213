import { Link } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
  return (
    <div className={mobileMenu ? "mobile-nav" : "mobile-nav show"}>
      <button onClick={handleMobileMenu} type="button" className="close-nav">
        <i className="fal fa-times-circle"></i>
      </button>
      <nav className="sidebar-nav">
        <ul className="metismenu" id="mobile-menu">
          <li>
            <a className="has-arrow" href="/">
              <p>ANASAYFA</p>
            </a>
          </li>
          <li>
            <Link to="/hakkimizda">
              <p>HAKKIMIZDA</p>
            </Link>
          </li>
          <li>
            <Link to="/sinav-takvimi">
              <p>SINAV TAKVİMİ</p>
            </Link>
          </li>
          <li>
            <Link to="/myk-nedir">
              <p>MYK NEDİR?</p>
            </Link>
          </li>
          <li>
            <Link to="/iletisim">
              <p>İLETİŞİM</p>
            </Link>
          </li>
        </ul>
      </nav>

      <div className="action-bar">
        <a href={`mailto:${organizationDetails.email}`}>
          <i className="fal fa-envelope"></i>
          <span className="action-bar-link1">{organizationDetails.email}</span>
        </a>
        <a href={`tel:${organizationDetails.phone}`}>
          <i className="fal fa-phone"></i>
          <span className="action-bar-link2">{organizationDetails.phone}</span>
        </a>
        <Link to="/iletisim" className="d-btn theme-btn">
          İletişim
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
