import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import Gizlilik from "../Components/About/Gizlilik";

const GizlilikPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="Gizlilik ve Güvenlik"
        bannerBg={Epilasyon}
        currentPage="Gizlilik ve Güvenlik"
      />
      <Gizlilik pt />
      <FooterTwo />
    </>
  );
};

export default GizlilikPage;
