import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import KalitePolitika from "../Components/About/KalitePolitika";

const KalitePolitikaPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="KALİTE POLİTİKALARIMIZ"
        bannerBg={Epilasyon}
        currentPage="KALİTE POLİTİKALARIMIZ"
      />
      <KalitePolitika pt />
      <FooterTwo />
    </>
  );
};

export default KalitePolitikaPage;
