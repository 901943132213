import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import MisyonVizyon from "../Components/About/MisyonVizyon";

const MisyonVizyonPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="MİSYONUMUZ VE VİZYONUMUZ"
        bannerBg={Epilasyon}
        currentPage="MİSYONUMUZ VE VİZYONUMUZ"
      />
      <MisyonVizyon pt />
      <FooterTwo />
    </>
  );
};

export default MisyonVizyonPage;
