import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import FiyatListesi from "../Components/Hizmetler/FiyatListesi";

const KapsamlarPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="FİYAT LİSTESİ"
        bannerBg={Epilasyon}
        currentPage="FİYAT LİSTESİ"
      />
      <FiyatListesi pt />
      <FooterTwo />
    </>
  );
};

export default KapsamlarPage;
