import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Güzellik from "../assets/images/guzellik.jpg";
import PageBanner from "../Components/PageBanner";
import ServicesOne from "../Components/Services/ServicesOne";
import ServicesCta from "../Components/Services/ServicesCta";
import BestFeatures from "../Components/Features/BestFeatures";

const ServicePage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="SINAV TAKVİMİ"
        bannerBg={Güzellik}
        currentPage="SINAV TAKVİMİ"
      />
      <ServicesOne />
      <ServicesCta />
      <BestFeatures />
      <FooterTwo />
    </>
  );
};

export default ServicePage;
