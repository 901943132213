import { Swiper, SwiperSlide } from "swiper/react";
import CiltBakimi from "../../assets/images/cilt-bakimi.jpg";
import Masaj from "../../assets/images/masaj.jpg";
import { EffectFade } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css/effect-fade";

const HeroTwo = () => {
  return (
    <section className="hero-wrapper hero-2">
      <Swiper
        navigation={true}
        modules={[Navigation, EffectFade]}
        effect={"fade"}
        loop
      >
        <SwiperSlide className="hero-slider-2">
          <div
            className="single-slide bg-cover"
            style={{ backgroundImage: `url(${CiltBakimi})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                  <div className="hero-contents pe-lg-3">
                    <h3
                      className="wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                    >
                      Misyonumuz
                    </h3>
                    <p
                      className="pe-lg-5 wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                      data-wow-delay=".4s"
                    >
                      MYK tarafından, yeterlilikleri tanımlanmış standartlar ile
                      ulusal olarak tanınan kalite güvence sistemi çerçevesinde
                      mesleki anlamda yeterliliklerin sağlandığının tescil
                      edilmesidir. Kendini sürekli geliştiren bir anlayışla
                      konusuna hakim bağımsızlık, tarafsızlık ve gizlilik
                      ilkeleri doğrultusunda, en güvenilir yöntemler ve uzman
                      personel ile sınav ve belgelendirme hizmeti sunmaktır.
                    </p>
                    <Link
                      to="/iletisim"
                      className="theme-btn me-sm-4 wow fadeInLeft"
                      data-wow-duration="1.2s"
                      data-wow-delay=".8s"
                    >
                      BİZE ULAŞIN
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="hero-slider-2">
          <div
            className="single-slide bg-cover"
            style={{ backgroundImage: `url(${Masaj})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                  <div className="hero-contents pe-lg-5">
                    <h2
                      className="wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                    >
                      Vizyonumuz
                    </h2>
                    <p
                      className="pe-lg-5 wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                      data-wow-delay=".4s"
                    >
                      Mesleki yeterliliği güvence altına alarak nitelikli insan
                      kaynağının oluşmasını sağlamak, ulusal ve uluslararası
                      ölçekte tanınan, etkin ve saygın bir kurum olmak.
                    </p>
                    <p>
                      Sektörde sunduğumuz hizmet ile öncü, uluslararası
                      platformda yer edinmiş lider bir kuruluş olmaktır.
                    </p>
                    <Link
                      to="/contact"
                      className="theme-btn me-sm-4 wow fadeInLeft"
                      data-wow-duration="1.2s"
                      data-wow-delay=".8s"
                    >
                      BİZE ULAŞIN
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HeroTwo;
