import { organizationDetails } from "../../data/constants";

const ContactPageContents = () => {
  return (
    <div className="contact-us-wrapper section-padding">
      <div className="container">
        <h2>İLETİŞİM BİLGİLERİ</h2>
        <div className="row eq-height">
          <div className="col-lg-6 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h3>Adres</h3>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="text">
                    <span>EMAİL</span>
                    <a href={`mailto:${organizationDetails.email}`}>
                      {organizationDetails.email}
                    </a>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>Bizi Arayın</span>
                    <a href={`tel:${organizationDetails.phone}`}>
                      {organizationDetails.phone}
                    </a>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-pin" />
                  </div>
                  <div className="text">
                    <span>Adres</span>
                    <h6>{organizationDetails.address}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <iframe
              title="google-maps"
              width="100%"
              height="600"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Elmal%C4%B1%20Mah.%207.Sk,%2018/602%20%20Muratpa%C5%9Fa/ANTALYA+(Yeni%20D%C3%BCnya%20Personel%20Belgelendirme%20Hizmetleri)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/distance-area-calculator.html">
                measure acres/hectares on map
              </a>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPageContents;
