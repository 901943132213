import ServicesTwo from "../Components/Services/ServicesTwo";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";

const HomeTwo = () => {
  return (
    <>
      <HeaderTwo />
      <ServicesTwo />
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
