import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import YetkiBelgesi from "../Components/About/YetkiBelgesi";

const YetkiBelgesiPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="YETKİ BELGESİ"
        bannerBg={Epilasyon}
        currentPage="YETKİ BELGESİ"
      />
      <YetkiBelgesi pt />
      <FooterTwo />
    </>
  );
};

export default YetkiBelgesiPage;
