import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import { useNavigate, Link } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();

  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>{organizationDetails.companyName}</span>
                      <h2>KAPSAMLARIMIZ </h2>
                    </div>
                    <p>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>HİZMETLERİMİZ</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <a
                                href="./17UY0280-3-cilt-bakım.pdf"
                                target="_blank"
                              >
                                CİLT BAKIM UYGULAYICISI SEVİYE 3 (Rev 1)
                              </a>
                            </td>
                            <td></td>
                            <td>17UY0280-3</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="./16UY0248-3-dovme.pdf" target="_blank">
                                DÖVME UYGULAYICISI SEVİYE 3 (Rev 2)
                              </a>
                            </td>
                            <td></td>
                            <td>16UY0248-3</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <a
                                href="./18UY0344-4-epilasyon.pdf"
                                target="_blank"
                              >
                                EPİLASYON UZMANI SEVİYE 4 (Rev 0)
                              </a>
                            </td>
                            <td>18UY0344-4</td>
                          </tr>
                          <tr>
                            <td>
                              <a
                                href="./16UY0244-4 guzellik.pdf"
                                target="_blank"
                              >
                                GÜZELLİK UZMANI SEVİYE 4 (Rev 2)
                              </a>
                            </td>
                            <td></td>
                            <td>16UY0244-4</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="./16UY0242-3-makyaj.pdf" target="_blank">
                                MAKYAJ UYGULAYICISI SEVİYE 3 (Rev 2)
                              </a>
                            </td>
                            <td></td>
                            <td>16UY0242-3</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="./14UY0197-3-masaj.pdf" target="_blank">
                                MASAJ UYGULAYICISI SEVİYE 3 (Rev 1)
                              </a>
                            </td>
                            <td></td>
                            <td>14UY0197-3</td>
                          </tr>
                        </tbody>
                      </Table>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center"> HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vizyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
