import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import Kapsamlar from "../Components/About/Kapsamlar";

const KapsamlarPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="KAPSAMLARIMIZ"
        bannerBg={Epilasyon}
        currentPage="KAPSAMLARIMIZ"
      />
      <Kapsamlar pt />
      <FooterTwo />
    </>
  );
};

export default KapsamlarPage;
