import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Preloader from "./Components/Preloader";
import ScrollTopBtn from "./Components/ScrollTopBtn";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import HomeTwo from "./pages/HomeTwo";
import ContactPage from "./pages/ContactPage";
import WhatisMyk from "./pages/WhatisMyk";
import Gizlilik from "./pages/Gizlilik";
import İtirazSikayet from "./pages/İtirazSikayet";
import KalitePolitika from "./pages/KalitePolitika";
import Kapsamlar from "./pages/Kapsamlar";
import MisyonVizyon from "./pages/MisyonVizyon";
import YasalDayanak from "./pages/YasalDayanak";
import YetkiBelgesi from "./pages/YetkiBelgesi";
import Yonetim from "./pages/Yonetim";
import Hizmetler from "./pages/Hizmetler";
import CiltBakim from "./pages/CiltBakim";
import Dovme from "./pages/Dovme";
import Epilasyon from "./pages/Epilasyon";
import Guzellik from "./pages/Guzellik";
import Makyaj from "./pages/Makyaj";
import Masaj from "./pages/Masaj";
import FiyatListesi from "./pages/FiyatListesi";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <>
        <ScrollTopBtn />
        <Routes>
          <Route path="/" element={<HomeTwo />} />
          <Route path="/hakkimizda" element={<AboutPage />} />
          <Route path="/sinav-takvimi" element={<ServicePage />} />
          <Route path="/iletisim" element={<ContactPage />} />
          <Route path="/myk-nedir" element={<WhatisMyk />} />
          <Route path="/yetki-belgesi" element={<YetkiBelgesi />} />
          <Route path="/gizlilik" element={<Gizlilik />} />

          <Route path="/kalite-politika" element={<KalitePolitika />} />
          <Route path="/kapsamlar" element={<Kapsamlar />} />
          <Route path="/misyon-vizyon" element={<MisyonVizyon />} />
          <Route path="/yasal-dayanak" element={<YasalDayanak />} />
          <Route path="/yonetim" element={<Yonetim />} />
          <Route path="/hizmetler" element={<Hizmetler />} />
          <Route path="/cilt-bakim" element={<CiltBakim />} />
          <Route path="/dovme" element={<Dovme />} />
          <Route path="/epilasyon" element={<Epilasyon />} />
          <Route path="/guzellik" element={<Guzellik />} />
          <Route path="/makyaj" element={<Makyaj />} />
          <Route path="/masaj" element={<Masaj />} />
          <Route path="/fiyat-listesi" element={<FiyatListesi />} />
        </Routes>
      </>
    );
  }
}

export default App;
