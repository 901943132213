import Makyaj from "../../assets/images/makyaj.jpg";
import "react-modal-video/scss/modal-video.scss";
import { Row, Container, Col, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();
  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12 mb-3">
                  <div className="img-block-pop-video">
                    <img src={Makyaj} alt="busico" />
                  </div>
                </div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>ŞİRKET HAKKINDA</span>
                      <h2 className="mt-2">
                        {organizationDetails.companyName}
                      </h2>
                    </div>
                    <cite>
                      "Bilginin sermaye olarak kabul edildiği günümüz
                      dünyasında, sosyal, kültürel ve ekonomik anlamda geleceğe
                      yatırım yapmak isteyen toplumlar öncelikle insan kaynağına
                      yatırım yapmalıdırlar. Bilgiyi elde etmenin yanı sıra bu
                      bilgiyi etkin bir şekilde kullanmak, geliştirmek ve
                      yenilemek de önemlidir."
                    </cite>
                    <p>
                      {organizationDetails.companyName} bu kavramın gerçekliğe
                      dökülmesi kapsamında 2022 yılında faaliyetlerine
                      başlamıştır. İnsan kaynağının niteliklerine göre
                      vasıflandırılması ve bu vasıflandırmaya uygun
                      belgelendirme yapılması günümüz bilgi toplumlarında bir
                      gereklilik haline gelmiştir. Bu gereklilikten hareketle,
                      "Personel Belgelendirmesi" ile alakalı çalışmaların yakın
                      takipçisi olmuş ve bu yapıya uyum sağlayan akredite bir
                      "Belgelendirme Kuruluşu" olarak hizmet sunmayı amaç
                      edinmiştir. Akreditasyon yetkilendirilmiş bir kuruluşun,
                      belli işleri yapan bir kuruluş ya da kişinin yeterliliğine
                      resmi tanınırlık verilmesi işlemidir. Akredite edilmiş bir
                      hizmet ise, o hizmetin sunumunun uluslararası kabul görmüş
                      kalite yeterlilik kriterlerine uygun olduğunun
                      akreditasyon hizmeti verme konusunda yetkilendirilmiş,
                      bağımsız ve tarafsız bir akreditasyon kuruluşu tarafından
                      tescil edildiğini ifade eder. Maddi kaygıdan uzak
                      hedefleri olan {organizationDetails.companyName}, sektörün
                      en tecrübeli profesyonelleri ile herkes için tarafsız ve
                      adil belgelendirme kurallarını uygulamaktadır. Bağımsızlık
                      ve tarafsızlık ilkelerinden asla taviz vermeyen{" "}
                      {organizationDetails.companyName} yasal mevzuat ve
                      yükümlülüklerini koşullar ne olursa olsun yerine getirmeyi
                      taahhüt ve beyan eder.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center"> HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vivzyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
