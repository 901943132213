import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useNavigate, useParams, Link } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();

  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>{organizationDetails.companyName}</span>
                      <h2>YASAL DAYANAĞIMIZ</h2>
                    </div>
                    <p>
                      “YENİ DÜNYA PERSONEL BELGELENDİRME MERKEZİ LTD.ŞTİ.
                      Mesleki Yeterlilik Kurumu (MYK) tarafından yetki
                      kapsamındaki ulusal yeterliliklere göre sınav ve
                      belgelendirme faaliyetlerini yürüten Yetkilendirilmiş
                      Belgelendirme Kuruluşudur. Yetkilendirilmiş Belgelendirme
                      Kuruluşu; Türk Akreditasyon Kurumu (TÜRKAK) tarafından ya
                      da Avrupa Akreditasyon Birliği bünyesinde çok taraflı
                      tanıma anlaşması imzalamış başka akreditasyon kurumlarınca
                      ilgili ulusal yeterliliklerde TS EN ISO/IEC 17024
                      standardına göre akredite edilmiş, Mesleki Yeterlilik
                      Kurumu (MYK) tarafından yapılan inceleme, denetim ve
                      değerlendirme sonucunda ulusal yeterliliklere göre sınav
                      ve belgelendirme faaliyetlerini yürütmek için gerekli
                      şartları sağladığı tespit edilen personel belgelendirme
                      kuruluşudur.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center"> HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vizyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
