import React from "react";

const Preloader = () => {
  return (
    <>
      <div id="preloader" className="preloader ">
        <div className="animation-preloader">
          <div className="spinner"></div>
          <div className="txt-loading">
            <span
              data-text-preloader="YENİ DÜNYA BELGELENDİRME"
              className="letters-loading"
            >
              YENİ DÜNYA BELGELENDİRME
            </span>
          </div>
          <p className="text-center">HOŞGELDİNİZ</p>
        </div>
        <div className="loader">
          <div className="row">
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
