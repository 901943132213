import AboutTwo from "../Components/About/AboutTwo";
import ServicesTwo from "../Components/Services/ServicesTwo";
import FooterTwo from "../Components/Footer/FooterTwo";
import WhyUs from "../Components/About/WhyUs";
import HeaderTwo from "../Components/Header/HeaderTwo";
import HeroTwo from "../Components/Hero/HeroTwo";
import Duyuru from "../Components/Duyurular/Duyuru";
import Uyarı from "../Components/Uyarı/Uyarı";
import BestFeatures from "../Components/Features/BestFeatures";

const HomeTwo = () => {
  return (
    <>
      {/* <Uyarı /> */}
      <HeaderTwo />
      <HeroTwo />
      <Duyuru />
      <AboutTwo />
      <ServicesTwo />
      <WhyUs />
      <BestFeatures />
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
