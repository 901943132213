import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Tatto from "../assets/images/tatto.jpg";
import PageBanner from "../Components/PageBanner";
import AboutMyk from "../Components/About/AboutMyk";

const MykPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="MYK NEDİR ?"
        bannerBg={Tatto}
        currentPage="MYK NEDİR ?"
      />
      <AboutMyk pt />
      <FooterTwo />
    </>
  );
};

export default MykPage;
