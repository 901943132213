import "react-modal-video/scss/modal-video.scss";
import { Row, Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { organizationDetails } from "../../data/constants";

const AboutOne = ({ pt }) => {
  const navigate = useNavigate();

  const handleAboutButton = () => {
    navigate("/hakkimizda");
  };

  return (
    <Container>
      <Row>
        <Col xs={9}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12">
                  <div className="img-block-pop-video"></div>
                </div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span>{organizationDetails.companyName}</span>
                      <h2> Yönetimin Taahhüdü</h2>
                    </div>
                    <p>
                      - TS EN ISO/IEC 17024 standardı şartlarına, Mesleki
                      Yeterlilik Kurumu (MYK) yetkilendirme şartlarına ve bağlı
                      bulunulan yasal ve mevzuat şartlarına uyulacağını,
                    </p>
                    <p>
                      -Akreditasyon kapsamında olan ve olmayan tüm
                      hizmetlerimizi, tarafsızlık, bağımsızlık ve doğruluk
                      ilkeleri doğrultusunda, müşterilerimizin, yasal otorite ve
                      kuruluşların ihtiyaçlarına cevap verecek şekilde
                      gerçekleştireceğimizi,
                    </p>
                    <p>
                      -Personel Belgelendirme faaliyetlerini yürütmede
                      tarafsızlığın öneminin bilincinde olduğumuzu, çıkar
                      çatışmalarını yönettiğimizi ve belgelendirme
                      faaliyetlerindeki objektifliği güvence altına aldığımızı,
                    </p>
                    <p>
                      -Verilen hizmetlerde ve faaliyetlerde personelimize idari,
                      ticari, mali veya herhangi bir konuda baskıda
                      bulunmayacağımızı ve bu tür bağlantılardan uzak
                      duracağımızı ve belgelendirme hizmetlerin sonuçlarını
                      etkileyecek hiçbir faaliyette bulunmayacağımızı,
                    </p>
                    <p>
                      -Çıkar çatışması oluşturacak herhangi bir ticari ilişkide
                      ya da ortaklık ilişkisinde bulunmayacağımızı,
                    </p>
                    <p>
                      -Başvuru yapan aday ve kuruluşlar ile ilgili elde
                      ettiğimiz bilgileri gizli bilgi olarak
                      değerlendireceğimizi ve yasal gereklilikler dışında
                      herhangi bir üçüncü tarafla paylaşmayacağımızı,
                    </p>
                    <p>
                      -Tüm politika ve prosedürlerimizi, adaylar arasında ayırım
                      oluşturmayacak şekilde düzenleyeceğimizi ve tüm
                      müşterilerimize ayırım gözetmeden eşit şartlarda hizmet
                      vereceğimizi,
                    </p>
                    <p>
                      -Sınav değerlendiricilerin aldıkları ücretlerin yaptıkları
                      iş sayısı veya sonuçları ile bağlantılı olmayacağını,
                    </p>
                    <p>
                      -ISO/IEC 17024 standardına aykırı olarak, hizmetlerine
                      erişimi engelleyecek veya yavaşlatacak prosedürler
                      uygulanmayacağını,
                    </p>

                    <p>
                      -Belgelendirme faaliyetlerinin yasal düzenlemelere, kalite
                      politikalarına ve müşteri beklentilerine uygun olmasını
                      sağlayacağını; bunun için gerekli tüm önlemleri alacağını
                      ve başlatacağını,
                    </p>

                    <p>
                      -Uygulanmakta olan Kalite Yönetim Sistemi’ne uyacağını,
                      sistemin en verimli ve etkin şekilde çalışması için
                      gerekli olan kaynakları tahsis edeceğini ve bunun tüm
                      çalışanlar tarafından anlaşılmasını sağlayacağını,
                    </p>
                    <p>
                      -Hizmetlerimizin tarafsızlığını tehdit edebilecek çıkar
                      çatışmalarını önleyici davranıldığını ve bunu düzenli
                      olarak analiz ederek ve tüm personelimiz tarafından bu
                      analizin anlaşılmasını sağlayarak bu sürekliliğin
                      sağlanmasını,
                    </p>
                    <p>
                      -Müşterilerimiz ile çıkar çatışmasına neden olabilecek
                      ticari veya diğer herhangi bir ilişkinin olmadığını,
                    </p>
                    <p>
                      -Adayların eşit koşullarda belgelendirmesine aykırı
                      davranışlar sergilenmeden, doğrulanabilir ve açık hizmet
                      sunulduğunu,
                    </p>
                    <p>
                      -Belgelendirme politika ve prosedürleri çerçevesinde tüm
                      başvuru sahipleri, aday ve kişilere tarafsız
                      davranıldığını,
                    </p>
                    <p>
                      -Başvuru sahibi ve adayların erişimini haksız olarak
                      zorlaştırıcı ve engelleyici uygulamalarda bulunulmadığını,
                    </p>

                    <p>
                      -Belgelendirmenin bir kurum veya kuruluşa üye olmak gibi
                      veya aşırı mali yük getirecek şartlar ile
                      sınırlandırılmadığını,
                    </p>
                    <p>
                      -Bütün bunların kuruluş politikamız, prosedürlerimiz,
                      ilkelerimiz, vizyonumuz, değerlerimiz, yapmış olduğumuz
                      sözleşmeler ve bu doküman ile garanti altına alındığını,
                      beyan ve taahhüt ederiz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col xs={3}>
          <section
            className={
              pt
                ? "about-section section-padding"
                : "about-section section-padding pt-0"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12"></div>
                <div className="">
                  <div className="block-contents">
                    <div className="section-title">
                      <span className="text-center">HAKKIMIZDA</span>

                      <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link onClick={() => navigate("/hakkimizda")}>
                          Hakkımızda
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yetki-belgesi")}>
                          Yetki Belgesi
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yasal-dayanak")}>
                          Yasal Dayanağımız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/yonetim")}>
                          Yönetim Taahhüdü
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/misyon-vizyon")}>
                          Misyon ve Vizyonumuz
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kalite-politika")}>
                          Kalite Politikamız
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/gizlilik")}>
                          Gizlilik ve Güvenlik
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/kapsamlar")}>
                          Kapsamlarımız
                        </Nav.Link>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutOne;
