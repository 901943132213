import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderTwo from "../Components/Header/HeaderTwo";
import Epilasyon from "../assets/images/epilasyon.jpg";
import PageBanner from "../Components/PageBanner";
import YasalDayanak from "../Components/About/YasalDayanak";

const YasalDayanakPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title="YASAL DAYANAĞIMIZ"
        bannerBg={Epilasyon}
        currentPage="YASAL DAYANAĞIMIZ"
      />
      <YasalDayanak pt />
      <FooterTwo />
    </>
  );
};

export default YasalDayanakPage;
